<template>
  <div class='basicInformation_box'>
    <div class='basicInformation'>
      <div class='basicInformation_text'>基本信息</div>
      <div class='info'>
                <div class='flex '>
                  <div class='name'>商户号：</div>
                  <div class='content'>{{userInfo.merchant[0].name}}</div>
                </div>
<!--                <div class='flex mt-4'>-->
<!--                  <div class='name'>真实姓名：</div>-->
<!--                  <div class='content'>{{}}</div>-->
<!--                </div>-->
                <div class='flex mt-4'>
                  <div class='name'>手机号：</div>
                  <div class='content'>{{userInfo.phone}}</div>
                </div>
<!--                <div class='flex mt-4'>-->
<!--                  <div class='name'>邮箱：</div>-->
<!--                  <div class='content'>{{userInfo.email}}</div>-->
<!--                </div>-->
                <div class='flex mt-4'>
                  <div class='name'>注册时间：</div>
                  <div class='content'>{{setCreatedAt(userInfo.createdAt)}}</div>
                </div>
<!--                <div class='flex mt-4'>-->
<!--                  <div class='name'>注册地址：</div>-->
<!--                  <div class='content'>中国  湖南省  长沙市</div>-->
<!--                </div>-->
<!--                <div class='flex mt-4'>-->
<!--                  <div class='name'>最后登录时间：</div>-->
<!--                  <div class='content'>{{userInfo.loginAt}}</div>-->
<!--                </div>-->
<!--                <div class='flex mt-4'>-->
<!--                  <div class='name'>最后登录IP：</div>-->
<!--                  <div class='content'>{{userInfo.loginIp}}</div>-->
<!--                </div>-->
<!--                <div class='flex mt-4'>-->
<!--                  <div class='name'>最后登录地址：</div>-->
<!--                  <div class='content'>中国  湖南省  长沙市</div>-->
<!--                </div>-->
      </div>
    </div>
        <div class='basicInformation accountSecurity mt-6'>
          <div class='basicInformation_text'>账户安全</div>
            <div><el-form
                class='form'
                ref="ruleForm"
                :model="form"
                :rules="rules"
                label-width="200px"
            >
              <el-form-item label="手机号" >
                <el-input  style="width:  400px;" :disabled="true" v-model.trim="form.phone" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label='原密码' prop='oldPassword'>
                <el-input placeholder='请输入原密码' v-model.trim='form.oldPassword' type='password'></el-input>
              </el-form-item>
              <el-form-item label='新密码' prop='newPassword'>
                <el-input placeholder='请输入新密码' v-model.trim='form.newPassword' type='password'></el-input>
              </el-form-item>
              <el-form-item label='确定密码' prop='confirmPassword'>
                <el-input placeholder='请输入确定密码' v-model.trim='form.confirmPassword' type='password'></el-input>
              </el-form-item>
              <el-form-item >
                <el-button style='margin-right: 20px' type="primary" @click="submitForm('ruleForm')">保存</el-button>
                <el-button @click="resetForm('ruleForm')">重置</el-button>
              </el-form-item>
            </el-form></div>
        </div>
    <div class='basicInformation accountInformation mt-6 ' >
      <div class='basicInformation_text'>账号信息</div>
      <div class="accountInformation_bottom">
        <div class='flex mt-10'>
          <div class='name accountBalance'>账户余额：</div>
                  <div class='content'><span class='text'>{{balance}}</span>元</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {changePassword ,logout} from "@/api/login";
import { getWalletBalance } from '@/api/costManagement'
import {dateFormat} from "@/utils/timejs.js";


export default {
  components: {  },
  data () {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if(value === this.form.oldPassword) {
        callback(new Error('新旧密码不能一致'));
      } else {
        callback();
      }
    };
    let  validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      form :{
        oldPassword:'', //原密码
        newPassword:'', //新密码
        confirmPassword:'', //确定密码
        phone:'',//手机号
      },
      balance:'',//余额
      rules :{
        oldPassword:[
          { required: true, message: '请输入原密码', trigger: 'blur' },
          { min:6,max:16, message: '密码6-18位字母加数字', trigger: 'blur' },
          {message:'请输入由字母加数字的组合', trigger: 'blur', pattern:new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/),},
        ],
        newPassword:[
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min:6,max:16, message: '密码6-18位字母加数字', trigger: 'blur' },
          {message:'请输入由字母加数字的组合', trigger: 'blur', pattern:new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/),},
          { validator: validatePass, trigger: 'blur' },
        ],
        confirmPassword:[
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' },
        ]
      }
    }
  },
  created () {

  },
  mounted () {
    this.getWalletBalance()
  },
  methods: {
    //region 账户安全
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
             await changePassword({
              phone:this.$encryptionByPhone(this.form.phone),
              oldPassword:this.$encryption(this.form.oldPassword),
              newPassword:this.$encryption(this.form.newPassword)
            })

            this.$message.success('修改成功，请重新登录')
            // window.location.href = String(process.env.VUE_APP_HOME_URL)
              await logout()
            window.location.href = String(process.env.VUE_APP_HOME_URL) + '?logout=1'
          }catch (e) {
            this.$message.error(e)
          }
        } else {

          return false;
        }
      });
    },
    //endregion

    //region 余额查询
    async getWalletBalance(){
      try{
        const { data } = await getWalletBalance()
        console.log(data)
        this.balance = data.balance
      } catch(e){
        console.log(e);
        this.$message.error(e)
      }
    },

    //endregion
  },
  computed:{
    setCreatedAt(){
      return (time)=>{
      return   dateFormat(time)
      }
    },
    ...mapState(['userInfo'])
  },
  watch:{
    userInfo:{
      handler(){
        this.form.phone = this.userInfo.phone
      },
      immediate:true
    }
  },
}

</script>

<style scoped lang='less'>
.mt-6 {
  margin-top: 20px;
}
/deep/.el-input {
  width: 400px;
}
.flex {
  display: flex;
}

.basicInformation {
  //border: 1px solid #eee;
  //height: 200px;
  .basicInformation_text {
    font-weight: 600;
    font-size: 16px;
    background: #f2f2f2;
    padding: 10px;
    line-height: 30px;
  }
  .info {
    padding: 20px 10px 20px 10px;
    border: 1px solid #eee;
    .mt-4 {
      margin-top: 10px;
    }
    .name {
      font-size: 14px;
      width: 200px;
      display: flex;
      justify-content: flex-end;
      margin-right:25px ;
      color: #909399;
    }
    .content {
      font-size: 14px;
      color: black;
    }
  }
}

.form {
  //height: 500px;
  padding-top: 20px;
  border: 1px solid #eee;
}
.accountInformation {
  //height: 190px;
  .name {
    width: 200px;
    display: flex;
    justify-content: flex-end;
    margin-right:20px ;
    color: #909399;
    font-size: 14px;
  }
  .content {
    font-size: 16px;
    color: black;
    .text {
      color: #f5222d;
      margin-right: 5px;
      font-weight: 600;
      font-size: 20px;
    }
  }
}
.accountInformation_bottom {
  border: 1px solid #eee;
  padding: 15px;

}
.accountBalance {
  line-height:25px ;
}
</style>